<template>
    <div v-if="isLoading" class="loading">
        <div class="text-center">
            <h5 class="mb-3">Loading...</h5>
            <b-spinner variant="primary" style="width: 4rem; height: 4rem;" />
        </div>
    </div>
    <div v-else-if="!list.length" class="no-data">No Data</div>
    <div v-else class="card__keywords_table">
        <div class="d-flex table-header-container">
            <div class="d-flex table-header align-items-center flex-grow-1">
                X / Twitter Posts
            </div>
            <div class="d-flex table-header justify-content-center align-items-center" style="width: 140px">
                Related Posts
            </div>
        </div>
        <div v-for="(item, index) in list" :key="'item' + index" class="d-flex table-body">
            <div class="keywords_container">
                <div class="row">
                    <div class="col-1" style="margin-right: -.9rem;">
                        <img :src="`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${item.posts[0].public_post_url}&size=256`"
                            alt="web-icon" width="24" height="24" class="social-thumbnail" />
                    </div>
                    <div class="col-11 d-flex align-items-center ps-0">
                        <a class="truncate-1" :title="item.posts[0].meta_title" :href="item.posts[0].public_post_url"
                            target="_blank">{{ item.posts[0].meta_title }}</a>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col truncate-1">
                        <img :src="item.thumbnail_url ? item.thumbnail_url : 'https://www.w3schools.com/howto/img_avatar.png'"
                            alt="avatar" width="24" height="24" class="social-thumbnail rounded-circle" />
                        <a :href="item.profile_url" style="text-decoration-color: var(--bs-secondary-color);" target="_blank">
                            <span class="card-text px-2 small text-muted">{{
                                item.posts[0].user_name }}</span>
                        </a>
                        <span class="text-muted me-2">|</span>
                        <span class="card-text small text-muted me-2">{{ item.num_followers.toLocaleString('en-US') }}
                            followers</span>
                        <span class="text-muted me-2">|</span>
                        <span class="card-text small text-muted"
                            v-html="item.posts[0].view_count ? item.posts[0].view_count.toLocaleString('en-US') + ' views ' : 0 + ' view '"></span>
                        <span class="text-muted me-2">|</span>
                        <span class="card-text small text-muted"
                            v-html="item.posts[0].reaction_count ? item.posts[0].reaction_count.toLocaleString('en-US') + ' likes ' : 0 + ' like '"></span>
                        <span class="text-muted me-2">|</span>
                        <span class="card-text small text-muted"
                            v-html="item.posts[0].quote_count ? item.posts[0].quote_count.toLocaleString('en-US') + ' quotes ' : 0 + ' quote '"></span>
                        <span class="text-muted me-2">|</span>
                        <span class="card-text small text-muted"
                            v-html="item.posts[0].repost_count ? item.posts[0].repost_count.toLocaleString('en-US') + ' reposts ' : 0 + ' repost '"></span>
                        <span class="text-muted me-2">|</span>
                        <span class="card-text small text-muted"
                            v-html="item.posts[0].bookmark_count ? item.posts[0].bookmark_count.toLocaleString('en-US') + ' bookmarks ' : 0 + ' bookmark '"></span>
                        <span class="text-muted me-2">|</span>
                        <span class="card-text small text-muted"
                            v-html="item.posts[0].post_time ? item.posts[0].post_time.split('T')[0] : ''"></span>
                    </div>
                </div>
                <div v-show="(item.posts[0].mentioned_names != null && item.posts[0].mentioned_names.length) || (item.posts[0].mentioned_urls != null && item.posts[0].mentioned_urls.length)"
                    class="row mt-1">
                    <div v-show="item.posts[0].mentioned_names.length" class="col">
                        <span class="small">Mentioned Names</span>
                        <div v-if="!twitterMentionedName[index]">
                            <span @click="showMentionedName(index)"
                                class="small text-muted cursor-pointer mt-1">Show</span>
                        </div>
                        <div v-else>
                            <span v-for="(name, name_index) in item.posts[0].mentioned_names" :key="name_index"
                                class="small truncate-1 text-muted">
                                <a :href="name.url" target="_blank" rel="noopener noreferrer">{{ name.name }}</a>
                            </span>
                            <span @click="hideMentionedName(index)"
                                class="small text-muted cursor-pointer mt-1">Hide</span>
                        </div>
                    </div>
                    <div v-show="item.posts[0].mentioned_urls.length" class="col">
                        <span class="small">Mentioned URLs</span>
                        <div v-if="!twitterMentionedUrl[index]">
                            <span @click="showMentionedUrl(index)"
                                class="small text-muted cursor-pointer mt-1">Show</span>
                        </div>
                        <div v-else>
                            <span v-for="(url, url_index) in item.posts[0].mentioned_urls" :key="url_index"
                                class="small truncate-1 text-muted">
                                <a :href="url" target="_blank" rel="noopener noreferrer">{{ url }}</a>
                            </span>
                            <span @click="hideMentionedUrl(index)"
                                class="small text-muted cursor-pointer mt-1">Hide</span>
                        </div>
                    </div>
                </div>
                <div v-if="item.posts[0].public_post.length > 300" class="row mt-2">
                    <span :class="['small', { 'truncate-3': !readMore[index] }]" v-html="highlightedText(item.posts[0].public_post)"></span>
                    <span v-if="!readMore[index]" @click="showMore(index)" class="small text-muted cursor-pointer">Show
                        More</span>
                    <span v-else @click="showLess(index)" class="small text-muted cursor-pointer">Show Less</span>
                </div>
                <div v-else class="row mt-2">
                    <span class="small" v-html="highlightedText(item.posts[0].public_post)"></span>
                </div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center" style="width: 140px;">
                {{ item.related_post ? item.related_post.length : '0' }}
                <button :class="['btn btn-outline-secondary mt-2 btn-sm', { 'disabled': !item.related_post.length }]"
                    @click="showModal(item, index, 'Related Posts')">View
                    Details</button>
            </div>
            <b-modal id="modal" :ref="'twitter_modal' + index" centered hide-header hide-footer scrollable size="lg"
                header-class="modal-header">
                <div class="card__keywords_table">
                    <div class="d-flex modal-table-header-container">
                        <div class="flex-grow-1 table-header d-flex align-items-center">Related Posts</div>
                    </div>
                    <div v-for="(list_item, list_index) in modalList" :key="'list_item' + list_index"
                        class="d-flex table-body">
                        <div class="keywords_container">
                            <div class="row">
                                <div class="col-1">
                                    <img :src="`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${list_item.posts[0].public_post_url}&size=256`"
                                        alt="web-icon" width="24" height="24" class="social-thumbnail" />
                                </div>
                                <div class="col-11 d-flex align-items-center" style="margin-left: -2rem">
                                    <a class="truncate-1" :title="list_item.posts[0].meta_title"
                                        :href="list_item.posts[0].public_post_url" target="_blank">{{
                                            list_item.posts[0].meta_title }}</a>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col truncate-1">
                                    <img :src="list_item.thumbnail_url ? list_item.thumbnail_url : 'https://www.w3schools.com/howto/img_avatar.png'"
                                        alt="avatar" width="24" height="24" class="social-thumbnail rounded-circle" />
                                    <a :href="list_item.profile_url"
                                        style="text-decoration-color: var(--bs-secondary-color);" target="_blank"><span
                                            class="card-text px-2 small text-muted">{{
                                                list_item.posts[0].user_name }}</span></a>
                                    <span class="text-muted me-2">|</span>
                                    <span class="card-text small text-muted me-2">{{
                                        list_item.num_followers.toLocaleString('en-US') }}
                                        followers</span>
                                    <span class="text-muted me-2">|</span>
                                    <span class="card-text small text-muted"
                                        v-html="list_item.posts[0].view_count ? list_item.posts[0].view_count.toLocaleString('en-US') + ' views ' : 0 + ' view '"></span>
                                    <span class="text-muted me-2">|</span>
                                    <span class="card-text small text-muted"
                                        v-html="list_item.posts[0].reaction_count ? list_item.posts[0].reaction_count.toLocaleString('en-US') + ' likes ' : 0 + ' like '"></span>
                                    <span class="text-muted me-2">|</span>
                                    <span class="card-text small text-muted"
                                        v-html="list_item.posts[0].quote_count ? list_item.posts[0].quote_count.toLocaleString('en-US') + ' quotes ' : 0 + ' quote '"></span>
                                    <span class="text-muted me-2">|</span>
                                    <span class="card-text small text-muted"
                                        v-html="list_item.posts[0].repost_count ? list_item.posts[0].repost_count.toLocaleString('en-US') + ' reposts ' : 0 + ' repost '"></span>
                                    <span class="text-muted me-2">|</span>
                                    <span class="card-text small text-muted"
                                        v-html="list_item.posts[0].bookmark_count ? list_item.posts[0].bookmark_count.toLocaleString('en-US') + ' bookmarks ' : 0 + ' bookmark '"></span>
                                    <span class="text-muted me-2">|</span>
                                    <span class="card-text small text-muted"
                                        v-html="list_item.posts[0].post_time ? list_item.posts[0].post_time.split('T')[0] : ''"></span>
                                </div>
                            </div>
                            <div v-show="list_item.posts[0].mentioned_names.length || list_item.posts[0].mentioned_urls.length"
                                class="row mt-1">
                                <div v-show="list_item.posts[0].mentioned_names.length" class="col">
                                    <span class="small">Mentioned Names</span>
                                    <div v-if="!twitterRelatedMentionedName[list_index]">
                                        <span @click="showRelatedMentionedName(list_index)"
                                            class="small text-muted cursor-pointer mt-1">Show</span>
                                    </div>
                                    <div v-else>
                                        <span v-for="(name, name_index) in list_item.posts[0].mentioned_names"
                                            :key="name_index" class="small truncate-1 text-muted">
                                            <a :href="name.url" target="_blank" rel="noopener noreferrer">{{ name.name
                                                }}</a>
                                        </span>
                                        <span @click="hideRelatedMentionedName(list_index)"
                                            class="small text-muted cursor-pointer mt-1">Hide</span>
                                    </div>
                                </div>
                                <div v-show="list_item.posts[0].mentioned_urls.length" class="col">
                                    <span class="small">Mentioned URLs</span>
                                    <div v-if="!twitterRelatedMentionedUrl[list_index]">
                                        <span @click="showRelatedMentionedUrl(list_index)"
                                            class="small text-muted cursor-pointer mt-1">Show</span>
                                    </div>
                                    <div v-else>
                                        <span v-for="(url, url_index) in list_item.posts[0].mentioned_urls"
                                            :key="url_index" class="small truncate-1 text-muted">
                                            <a :href="url" target="_blank" rel="noopener noreferrer">{{ url
                                                }}</a>
                                        </span>
                                        <span @click="hideRelatedMentionedUrl(list_index)"
                                            class="small text-muted cursor-pointer mt-1">Hide</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="list_item.posts[0].public_post.length > 325" class="row mt-2">
                                <span :class="['small', { 'truncate-3': !readMore[list_index] }]">{{
                                    list_item.posts[0].public_post }}</span>
                                <span v-if="!readMore[list_index]" @click="showMore(list_index)"
                                    class="small text-muted cursor-pointer">Show
                                    More</span>
                                <span v-else @click="showLess(list_index)" class="small text-muted cursor-pointer">Show
                                    Less</span>
                            </div>
                            <div v-else class="row mt-2">
                                <span class="small">{{ list_item.posts[0].public_post }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
export default {
    name: "twitter-list",
    props: {
        list: { type: Array, default: () => [] },
        isLoading: { type: Boolean, default: true },
        phrases: { type: Object, default: () => {} }
    },
    data() {
        return {
            modalList: [],
            readMore: {},
            twitterMentionedName: {},
            twitterRelatedMentionedName: {},
            twitterMentionedUrl: {},
            twitterRelatedMentionedUrl: {}
        };
    },
    computed: {
        highlightedText() {
            return (text) => {
                let highlightedText = text;
                const sortedPhrases = [...this.phrases.phrases_used].sort((a, b) => b.word.length - a.word.length);

                sortedPhrases.forEach(phrase => {
                const regex = new RegExp(`(${phrase.word})`, 'gi');
                // console.log(regex);
                
                highlightedText = highlightedText.replace(regex, '<span style="color: #318CE7;">$1</span>');
                // console.log(highlightedText);
                });

                return highlightedText;
            };
            }
    },
    methods: {
        showModal(item, index, header) {
            if (header === 'Twitter Comment') {
                this.modalList = item.posts[0].comment_list;
                this.modalList.public_post_url = item.posts[0].public_post_url;
            } else {
                this.modalList = item.related_post;
            }
            this.modalList.header = header;
            this.$refs['twitter_modal' + index][0].show();
        },
        showMore(id) {
            this.$set(this.readMore, id, true);
        },
        showLess(id) {
            this.$set(this.readMore, id, false);
        },
        showMentionedName(id) {
            this.$set(this.twitterMentionedName, id, true);
        },
        hideMentionedName(id) {
            this.$set(this.twitterMentionedName, id, false);
        },
        showRelatedMentionedName(id) {
            this.$set(this.twitterRelatedMentionedName, id, true);
        },
        hideRelatedMentionedName(id) {
            this.$set(this.twitterRelatedMentionedName, id, false);
        },
        showMentionedUrl(id) {
            this.$set(this.twitterMentionedUrl, id, true);
        },
        hideMentionedUrl(id) {
            this.$set(this.twitterMentionedUrl, id, false);
        },
        showRelatedMentionedUrl(id) {
            this.$set(this.twitterRelatedMentionedUrl, id, true);
        },
        hideRelatedMentionedUrl(id) {
            this.$set(this.twitterRelatedMentionedUrl, id, false);
        }
    },
};
</script>

<style lang="css" scoped>
.card__keywords_table {
    height: 100%;
}

.table-header-container {
    font-weight: bold;
    position: sticky;
    top: -2rem;
    z-index: 1;
}

.modal-table-header-container {
    font-weight: bold;
    position: sticky;
    top: -1rem;
    z-index: 1;
}

.table-header {
    font-weight: bold;
    position: sticky;
    top: -2rem;
    z-index: 1;
    padding: 1rem;
    background: #e1f0fc;
    color: #352f36;
}

.table-header:last-child {
    cursor: pointer;
}

.table-body {
    width: 100%;
    border-left: 2px solid #f5f5f5;
    border-right: 2px solid #f5f5f5;
}

.table-body:nth-child(odd) {
    background: #fafafa;
}

.table-body:last-child {
    border-bottom: 2px solid #f5f5f5;
}

.no-data,
.loading {
    height: 100vh;
    max-height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-data {
    font-weight: bold;
    font-size: xx-large;
}

.info-tooltip {
    font-size: 14px;
    margin-left: 4px;
    user-select: none;
}

.sort-icon {
    font-size: 16px;
    margin-right: 4px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.sort-icon.hidden {
    color: #e1f0fc;
}

.keywords_container {
    display: flex;
    flex-direction: column;
    width: 200px;
    flex-grow: 1;
    margin: 1rem 0 1rem 1rem;
}

.engagement_container {
    display: flex;
    flex-direction: column;
    width: 100px;
    flex-grow: 1;
    margin: 1rem 0 1rem 1rem;
}
</style>