<template>
  <div v-if="isLoading" class="loading">
    <div class="text-center">
      <h5 class="mb-3">Loading...</h5>
      <b-spinner variant="primary" style="width: 4rem; height: 4rem;" />
    </div>
  </div>
  <div v-else-if="!list.length" class="no-data">
    No Data
  </div>
  <div v-else class="card__keywords_table">
    <div class="d-flex table-header-container">
      <div class="flex-grow-1 table-header d-flex align-items-center">Crunchbase Profile</div>
      <div class="table-header d-flex justify-content-center align-items-center text-center" style="width: 100px"
        @click="sortColumn('rank', rankSortFunction)">
        <div class="d-flex flex-column justify-content-between">
          <span :class="{
            'material-icons-outlined': true,
            'sort-icon': true,
            'hidden': sortedColumn === 'rank' && sortType === 'desc'
          }">
            keyboard_arrow_up
          </span>
          <span :class="{
            'material-icons-outlined': true,
            'sort-icon': true,
            'hidden': sortedColumn === 'rank' && sortType === 'asc'
          }">
            keyboard_arrow_down
          </span>
        </div>
        Rank
      </div>
      <div class="table-header d-flex justify-content-center align-items-center text-center" style="width: 115px"
        @click="sortColumn('total_funding_usd', totalFundingSortFunction)">
        <div class="d-flex flex-column justify-content-between">
          <span :class="{
            'material-icons-outlined': true,
            'sort-icon': true,
            'hidden': sortedColumn === 'total_funding_usd' && sortType === 'desc'
          }">
            keyboard_arrow_up
          </span>
          <span :class="{
            'material-icons-outlined': true,
            'sort-icon': true,
            'hidden': sortedColumn === 'total_funding_usd' && sortType === 'asc'
          }">
            keyboard_arrow_down
          </span>
        </div>
        Total Funding
      </div>
      <div class="table-header d-flex justify-content-center align-items-center text-center" style="width: 120px">
        Founded On
      </div>
      <div class="table-header d-flex justify-content-center align-items-center text-center" style="width: 120px">
        Recent News
      </div>
      <div class="table-header d-flex justify-content-center align-items-center text-center" style="width: 120px">
        Funding Rounds
      </div>
    </div>
    <div v-for="(item, index) in list" :key="'item' + index" class="d-flex table-body">
      <div class="keywords_container">
        <div class="img_container">
          <img :src="!item.thumbnail_url || item.thumbnail_url.includes('default_thumbnail.png')
            ? `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${item.website}&size=256`
            : item.thumbnail_url
            " alt="web-icon" width="32" height="32" class="thumbnail" />
        </div>
        <div style="margin-left: -4rem;">
          <div style="margin-left: 4rem;">
            <a v-if="item.name" :href="item.profile_url" target="_blank" rel="noopener noreferrer" :title="item.name">
              <h6 class="fw-bold text-black truncate-1">{{ item.name }}</h6>
            </a>
            <a :href="item.website" target="_blank" rel="noopener noreferrer" class="truncate-1" :title="item.website">
              {{ item.website }}
            </a>
          </div>
          <small v-if="item.category" class="truncate-1 mt-1 mb-0" :title="item.category.join(', ')"
            v-html="item.category.join(', ')" />
          <small v-if="item.type" class="text-muted mt-1 mb-0">{{ item.type }}</small><br>
          <small v-if="item.type" class="text-muted mt-1 mb-0">{{ item.employee_count }} employees</small>
          <div v-if="item.description.length > 200" class="mt-1 mb-0">
            <small :class="{ 'truncate-2': !readMore[index] }" class="text-muted" :title="item.description"
              v-html="item.description" />
            <small v-if="!readMore[index]" @click="showMore(index)"
              class="cursor-pointer text-decoration-underline">Show More</small>
            <small v-else @click="showLess(index)" class="cursor-pointer text-decoration-underline"><br>Show
              Less</small>
          </div>
          <div v-else class="mt-1 mb-0">
            <small class="text-muted" :title="item.description" v-html="item.description" />
          </div>
          <small v-if="item.location" class="truncate-1 mt-1 mb-0" :title="item.location.join(', ')"
            v-html="item.location.join(', ')" />
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center" :class="{ 'text-danger': item.rank > 100 }"
        style="width: 100px;" v-text="item.rank <= 100 ? item.rank : '> 100'"></div>
      <div class="d-flex justify-content-center align-items-center" style="width: 115px;">{{ item.total_funding_usd }}
      </div>
      <div class="d-flex justify-content-center align-items-center" style="width: 120px;">{{ item.founded_on }}</div>
      <div class="d-flex flex-column justify-content-center align-items-center text-center" style="width: 120px">
        {{ item.recent_news_count ?? item.recent_news.length }}
        <button @click="showModal(item, index, 'Recent News')"
          :class="['btn btn-outline-secondary btn-sm mt-2', { 'disabled': !item.recent_news.length }]">View
          Details</button>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center text-center" style="width: 120px">
        &nbsp;
        <button @click="showModal(item, index, 'Funding Rounds')"
          :class="['btn btn-outline-secondary btn-sm mt-2', { 'disabled': !item.funding_rounds.length }]">View
          Details</button>
      </div>
      <b-modal id="modal" :ref="'modal' + index" centered hide-header hide-footer scrollable size="lg"
        header-class="modal-header">
        <div class="card__keywords_table">
          <div class="d-flex modal-table-header-container">
            <div v-if="modalList.header === 'Recent News'" class="flex-grow-1 table-header d-flex align-items-center">
              Recent News
            </div>
            <div v-if="modalList.header === 'Funding Rounds'"
              class="flex-grow-1 table-header d-flex align-items-center justify-content-center text-center" style="width: 160px;">
              Announced Date
            </div>
            <div v-if="modalList.header === 'Funding Rounds'" class="table-header d-flex align-items-center justify-content-center text-center" style="width: 160px;">
              Transaction Name
            </div>
            <div v-if="modalList.header === 'Funding Rounds'" class="table-header d-flex align-items-center justify-content-center text-center" style="width: 160px;">
              Number of investors
            </div>
            <div v-if="modalList.header === 'Funding Rounds'" class="table-header d-flex align-items-center justify-content-center text-center" style="width: 160px;">
              Money Raised
            </div>
            <div v-if="modalList.header === 'Funding Rounds'" class="table-header d-flex align-items-center justify-content-center text-center" style="width: 160px;">
              Lead Investor
            </div>
          </div>
          <div v-for="(list_item, list_index) in modalList" :key="'list_item' + list_index" class="d-flex table-body">
            <div v-if="header === 'Recent News' && ['News', 'Funding Round'].includes(list_item.type)"
              class="m-3 w-100">
              <div class="row">
                <div class="col-1 d-flex justify-content-center align-items-center">
                  <img
                    :src="`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${list_item.url}&size=256`"
                    alt="web-icon" width="24" height="24" class="social-thumbnail" />
                </div>
                <div class="col-11 d-flex flex-column justify-content-start">
                  <span class="small text-muted">{{ `${list_item.type} | ${list_item.date}` }}</span>
                  <a :title="list_item.title" :href="list_item.url" target="_blank">
                    {{ recentNewsTitleFormat(list_item) }}
                  </a>
                </div>
              </div>
            </div>
            <div v-if="modalList.header === 'Funding Rounds'" class="d-flex m-2 justify-content-center align-items-center text-center" style="width: 160px;">
              {{ list_item.announced_on }}
            </div>
            <div v-if="modalList.header === 'Funding Rounds'" class="d-flex m-2 justify-content-center align-items-center text-center" style="width: 160px;">
              {{ list_item.name }}
            </div>
            <div v-if="modalList.header === 'Funding Rounds'" class="d-flex m-2 justify-content-center align-items-center text-center" style="width: 160px;">
              {{ list_item.investors }}
            </div>
            <div v-if="modalList.header === 'Funding Rounds'" class="d-flex m-2 justify-content-center align-items-center text-center" style="width: 160px;">
              ${{ list_item.raised_usd }}
            </div>
            <div v-if="modalList.header === 'Funding Rounds'" class="d-flex m-2 justify-content-center align-items-center text-center" style="width: 160px;">
              {{ list_item.lead_investors ? list_item.lead_investors.join(', ') : '-' }}
            </div>
          </div>
        </div>
        <div v-if="modalList.header == 'Recent News'" class="row mt-2">
          <a :href="modalList.recent_news_url" target="_blank" rel="noopener noreferrer"
            style="text-decoration: none;"><span class="small text-muted">See More</span></a>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'crunchbase-list',
  props: {
    list: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: true },
  },
  data() {
    return {
      rankSortFunction: {
        asc: (a, b) => a.rank - b.rank,
        desc: (a, b) => b.rank - a.rank
      },
      employeeCountSortFunction: {
        asc: (a, b) => a.employee_count.localeCompare(b.employee_count),
        desc: (a, b) => b.employee_count.localeCompare(a.employee_count)
      },
      totalFundingSortFunction: {
        asc: (a, b) => a.total_funding_usd.localeCompare(b.total_funding_usd),
        desc: (a, b) => b.total_funding_usd.localeCompare(a.total_funding_usd)
      },
      sortedColumn: '',
      sortType: '',
      readMore: {},
      modalList: []
    }
  },
  methods: {
    sortColumn(column, sortFunction) {
      if (this.sortedColumn === column) {
        this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
      } else {
        this.sortedColumn = column;
        this.sortType = 'desc';
      }
      this.list.sort(sortFunction[this.sortType]);
    },
    showMore(id) {
      this.$set(this.readMore, id, true);
    },
    showLess(id) {
      this.$set(this.readMore, id, false);
    },
    showModal(item, index, header) {
      if (header === 'Recent News') {
        this.modalList = item.recent_news;
        this.modalList.recent_news_url = item.recent_news_url;
      } else if (header === 'Funding Rounds') {
        this.modalList = item.funding_rounds;
      }
      console.log(JSON.stringify(this.modalList, null, 4));
      this.modalList.header = header;
      this.$refs['modal' + index][0].show();
    },
    recentNewsTitleFormat(item) {
      if (item.type === 'News') {
        return `${item.publisher} - ${item.title}`;
      } else if (item.type === 'Funding Round') {
        if (item.investors.length > 2) {
          return `${item.title} - ${item.investee.name} raised $${item.fund_raised_usd} from ${item.investors[0].name} and ${item.investors.length - 1} other investors`;
        } else if (item.investors.length > 1) {
          return `${item.title} - ${item.investee.name} raised $${item.fund_raised_usd} from ${item.investors[0].name} and ${item.investors[1].name}`;
        } else {
          return `${item.title} - ${item.investee.name} raised $${item.fund_raised_usd} from ${item.investors[0].name}`;
        }
      }
    }
  }
}
</script>

<style lang="css" scoped>
.card__keywords_table {
  width: 100%;
  height: 100%;
}

.table-header-container {
  font-weight: bold;
  position: sticky;
  top: -2rem;
  z-index: 1;
}

.modal-table-header-container {
  font-weight: bold;
  position: sticky;
  top: -1rem;
  z-index: 1;
}

::v-deep .modal-header {
  justify-content: space-between;
}

::v-deep .modal-header button.close {
  font-size: 32px;
  line-height: 0;
  height: 32px;
  position: relative;
  bottom: 3px;
}

.table-header {
  padding: 1rem;
  background: #e1f0fc;
  color: #352f36;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.table-header:first-child {
  cursor: default;
}

.table-body {
  width: 100%;
  border-left: 2px solid #f5f5f5;
  border-right: 2px solid #f5f5f5;
}

.table-body:nth-child(odd) {
  background: #fafafa;
}

.table-body:last-child {
  border-bottom: 2px solid #f5f5f5;
}

.keywords_container {
  display: flex;
  width: 200px;
  flex-grow: 1;
  gap: 0.75rem;
  margin: 1rem 0 1rem 1rem;
}

.sort-icon {
  font-size: 16px;
  margin-right: 4px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.sort-icon.hidden {
  color: #e1f0fc;
}

.img_container {
  padding: 8px;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 12px;
  height: fit-content;
}

.no-data,
.loading {
  height: 100vh;
  max-height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data {
  font-weight: bold;
  font-size: xx-large;
}

.thumbnail {
  height: 32px;
  object-fit: contain;
  aspect-ratio: 1/1;
}

.info-tooltip {
  font-size: 14px;
  margin-left: 4px;
  user-select: none;
}

.btn-action {
  color: #707070;
  margin: 0 auto;
  border-radius: 50%;
  padding: 2px;
}

.btn-action:hover {
  background-color: var(--color-primary-light);
  border-radius: 50%;
  transition: all 0.3s ease-out;
}

.action-menu-dropdown {
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
  padding: 0.4rem 0.5rem;
  text-align: left;
  transform: translate(calc(-100% + 40px), 0px);
  width: 210px;
  color: #272727;
}

.action-menu:hover .action-menu-dropdown {
  display: flex;
}

.action-menu-dropdown span {
  cursor: pointer;
  user-select: none;
  padding: 0.1rem 0.5rem 0.1rem 0.2rem;
  margin: 0.1rem 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #707070;
}

.action-menu-dropdown span .material-icons-outlined {
  font-size: 20px;
  color: #707070;
}

.action-menu-dropdown span:hover {
  background-color: #e6e6e69c;
  transition: all 0.2s ease-out;
  border-radius: 5px;
  color: grey;
}

.button-search {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
}
</style>

<style lang="css">
.text-insight-list .k-progress-outer {
  padding-right: 0;
}
</style>